const metaData = {
  SITE_NAME: `Nalmefene HCl injection`,
  SITE_DEFAULT_DESCRIPTION: `Your Site`,
  SITE_DEFAULT_KEYWORDS: ["key", "your site"],
  PATIENT_JOB_CODE: `XX-XXXX/March 2023`,
  HCP_JOB_CODE: `XX-XXXX/March 2023`,
  CURRENT_YEAR: new Date().getFullYear(),
};

/**
 * HEADER_TYPE : fixed or regular
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */
const ISI_HEIGHT = 142;
const siteOptions = {
  INDICATIONS: ["hcp", "patient"],
  HEADER_TYPE: "fixed",
  STICKY_HEIGHT: ISI_HEIGHT - 25,
  ISI_OPTIONS: {
    TYPE: "sticky",
    STICKY_STYLES: {
      backgroundColor: "#ffffff",
      height: ISI_HEIGHT,
      width: "100%",
      boxShadow: "-1px -5px 15px 0px rgba(0,0,0,0.10)",
      paddingTop: 5,
    },
  },
};
const hcpModalInfo = {
  TITLE: `<h2>Intended for U.S. Residents Only<h2>`,
  BODY: `<h3>Welcome to BRANDX.com</h3><p>Are you a patient or a healthcare professional?</p>`,
  BUTTON_ONE_CAPTION: `Patient`,
  BUTTON_TWO_CAPTION: `Healthcare professional`,
  CLASS_NAME: `brandx-modal brandx-hcp-modal`,
};
const externalLinkInfo = {
  TITLE: `<p>You are leaving  NalmefeneHCl.com and entering a site that is neither the property of nor controlled by Purdue Pharma L.P. or its subsidiaries.<span class="new_line"><br><br>Do you wish to leave NalmefeneHCl.com?</span></p>`,
  LINK_CLASS_NAME: `brandx-external-link`,
  OVERLAY_CLASS_NAME: `brandx-modal brandx-external-modal`,
};
const externalLinkInfoPI = {
  TITLE: `<p>This link directs you to the National Library of Medicine's DailyMed website, which houses FDA-approved product labeling information.<span class="new_line"><br><br>Do you wish to leave NalmefeneHCl.com?</span></p>`,
  LINK_CLASS_NAME: `brandx-external-link`,
  OVERLAY_CLASS_NAME: `brandx-modal brandx-external-modal`,
};
const appConfigs = {
  metaData: metaData,
  siteOptions: siteOptions,
  hcpModalInfo: hcpModalInfo,
  externalLinkInfo: externalLinkInfo,
  externalLinkInfoPI: externalLinkInfoPI,
};

export default appConfigs;
