import AppProvider from './src/codes/providers/appProvider';

function loadScript(url, callback) {
    const script = document.createElement('script');
    script.src = url;

    // Handle successful loading
    script.onload = callback;

    // Handle errors in script loading
    script.onerror = function() {
        console.error(`Failed to load script ${url}`);
    };

    document.body.appendChild(script);
}

function loadStyle(url) {
    const link = document.createElement('link');
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = url;
    document.head.appendChild(link);
}

function initializeScripts() {
    // "Do Not Sell" properties
    window.globalDnsDeployment = false;
    window.claripHost = 'purdue.clarip.com';
    window.claripCdnHost = 'cdn.clarip.com';
    window.clientName = 'purdue1';
    window.dnsControllerType = 'option-2';
    window.dnsSubmissionUrl = 'https://purdue.clarip.com/dsr/success?brand=NALMEFENE';
    window.standardDsrFormUrl = 'https://purdue.clarip.com/dsr/create?brand=NALMEFENE&type=3';
    window.createDoNotSellLink = 0;
    window.doNotSellLinkSelector = '#clarip-do-not-sell-link';
    window.doNotSellCookieName = 'clarip_dns_cookie';
    window.doNotSellCookieValue = 1;
    window.doNotSellCookieExpirationAge = 1825;
    window.enableEnforcementScope = false;

    loadStyle('https://cdn.clarip.com/purdue1/donotsell/assets/css/donotsell-extended.min.css');
    
    loadScript('https://cdn.clarip.com/purdue1/donotsell/assets/js/block-3rd-parties.min.js');
    loadScript('https://cdn.clarip.com/purdue1/donotsell/assets/js/donotsell-block.min.js');

    // Cookie Consent properties
    window.claripCdnHost = "cdn.clarip.com";
    window.claripClientName = "purdue";
    window.claripCookieConsentHost = "purdue.clarip.com";
    window.claripCookieManagerIdentifier = "1d5401429a27f8e2973f";

    loadStyle('//cdn.clarip.com/purdue/cookieconsent/assets/css/cookieconsent.min.css');
    
    loadScript('//cdn.clarip.com/purdue/cookieconsent/assets/js/cookieconsent.min.js');
    loadScript('//cdn.clarip.com/purdue/cookieconsent/assets/js/clarip-cookie-manager.min.js');

    setTimeout(() => {
        window.dispatchEvent(new Event('DOMContentLoaded'));
    }, 1000);
}

window.addEventListener('load', initializeScripts);

export const wrapRootElement = AppProvider;
