exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-nalmefene-hcl-index-js": () => import("./../../../src/pages/about-nalmefene-hcl/index.js" /* webpackChunkName: "component---src-pages-about-nalmefene-hcl-index-js" */),
  "component---src-pages-administration-and-dosing-index-js": () => import("./../../../src/pages/administration-and-dosing/index.js" /* webpackChunkName: "component---src-pages-administration-and-dosing-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ordering-resources-index-js": () => import("./../../../src/pages/ordering-resources/index.js" /* webpackChunkName: "component---src-pages-ordering-resources-index-js" */),
  "component---src-pages-overdose-crisis-index-js": () => import("./../../../src/pages/overdose-crisis/index.js" /* webpackChunkName: "component---src-pages-overdose-crisis-index-js" */),
  "component---src-pages-request-a-rep-js": () => import("./../../../src/pages/request-a-rep.js" /* webpackChunkName: "component---src-pages-request-a-rep-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

